let Socket = ''
let setIntervalWesocketPush = null

export function createSocket() {
    if (!Socket) {
        console.log('建立websocket连接')
        Socket = new WebSocket(process.env.VUE_APP_WEBSOCKET_URL)
        Socket.onopen = onopenWS
        Socket.onmessage = onmessageWS
        Socket.onerror = onerrorWS
        Socket.onclose = oncloseWS
    } else {
        console.log('websocket已连接')
    }
}

export function onopenWS() {
    sendPing()
}

export function onerrorWS() {
    clearInterval(setIntervalWesocketPush)
    Socket.close()
    createSocket()
}

export function onmessageWS(e) {
    window.dispatchEvent(new CustomEvent('onmessageWS', {
        detail: {
            data: e
        }
    }))
}
export function sendWSPush(obj) {

    if (Socket !== null && Socket.readyState === 3) {
        Socket.close()
        createSocket() //重连
    } else if (Socket.readyState === 1) {
        Socket.send(JSON.stringify(obj))
    } else if (Socket.readyState === 0) {
        setTimeout(() => {
            Socket.send(JSON.stringify(obj))
        }, 3000)
    }
}

export function oncloseWS() {
    clearInterval(setIntervalWesocketPush)
}

export function sendPing() {

    setIntervalWesocketPush = setInterval(() => {
        let msg ={
            "action":"ping"
        }
        // 傳送一個訊息
        Socket.send(msg);
    }, 5000 * 60)
}
