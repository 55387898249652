<!--！！！该页面废弃使用。字段修改和多语言已不支持该页面，重新启用该页面需要修正接口字段和多语言校准-->
<template>
    <v-q-r-code-template>
        <div class="info-area">
            <div class="logo-title">
                <span class="via-mobile360-logo via-icon"></span>
                <span class="title">Welcome to VIA Fleet</span>
            </div>
            <div class="name-area" v-if="company">
                <span>{{company.Name}}</span>
            </div>
            <div class="select-input">
                <select style="width: 100%" v-model="selectVehicle">
                    <option value="">Please Select Plate Number</option>
                    <option v-for="item in vehicleList" :value="item.Id">{{item.Name}}</option>
                </select>
            </div>
            <div class="login-button">
                <button @click="sendBindQrcode">Generate QRCode</button>
            </div>
        </div>
        <div class="qrcode-area">

            <img :src="qrcodeImage" class="content">

        </div>

    </v-q-r-code-template>
</template>

<script>
    import vQRCodeTemplate from "./QRCodeTemplate"
    import {createSocket, sendWSPush} from '../../assets/js/WebSocket'

    export default {
        name: "GenerateQRCode",
        components: {
            vQRCodeTemplate
        },
        data() {
            return {
                vehicleList: null,
                selectVehicle: "",
                company: null,
                currentUserInfo: null,
                qrcodeImage: null
            }
        },
        methods: {
            sendBindQrcode() {
                if (!this.selectVehicle) {
                    alert('need VehicleId & UserId!!');
                    return;
                }

                let msg = {
                    "action": "bind-qrcode",
                    VehicleId: this.selectVehicle,
                    UserId: this.currentUserInfo.Id
                }
                sendWSPush(msg);
            },
            getDataResult(e) {
                let obj = JSON.parse(e.detail.data.data);
                switch (obj.action) {
                    case 'bind-qrcode':	 //"action":"bind-qrcode" 之返回
                        this.qrcodeImage = obj.QRCode
                        break;
                    case 'bind-result': //Device綁定成功後，透過此api接收綁定結果
                        //顯示綁定結果
                        this.$message({
                            message: 'Binded successfully.',
                            type: 'success'
                        })
                        console.log("bind success")
                        break;
                    case 'ping':
                        console.log("ping success")
                        break;

                }
            }
        },
        computed: {},
        created() {

            this.$serverApi.vehicles.vehicleList((data) => {
                this.vehicleList = data;
            });
            if (this.$store.getters.currentUserData)
                this.currentUserInfo = this.$store.getters.currentUserData;
            else {
                this.$serverApi.user.getUserInfo((data) => {
                    this.currentUserInfo = data;
                });
            }

            this.$serverApi.company.getCompany((data) => {
                this.company = data;
            })

            createSocket();//创建

            window.addEventListener('onmessageWS', this.getDataResult)

            //根据需要，销毁事件监听
            //window.removeEventListener('onmessageWS', getDataFunc)

        }
    }
</script>

<style scoped>
    .info-area {
        height: 50%;
        padding: 0.9rem 0.6rem 0 0.6rem;
        text-align: left;

    }

    .via-icon {
        font-size: 1rem;
        vertical-align: middle;
    }

    .title {
        font-size: 0.5rem;
        vertical-align: middle;
        margin-left: 0.1rem;
    }

    .name-area {
        margin-top: 0.9rem;
        text-align: left;
        font-size: 0.4rem;
    }

    .select-input {
        margin-top: 0.5rem;
    }

    .select-input select {
        border-radius: 0.1rem;
        height: 0.9rem;
        width: 100%;
        font-size: 0.32rem;
        padding: 0 0.15rem;
        color: #909090;
        border: 0;
        outline-style: none;
    }

    .login-button {
        margin-top: 0.8rem;
        text-align: center;
    }

    .login-button button {
        width: 80%;
    }

    .qrcode-area {
        height: 50%;
        background-color: white;
        text-align: center;
        padding-top: 0.6rem;
    }

    .qrcode-area .content {
        width: 4rem;
        height: 4rem;
        border: #f7f7f7 1px solid;
        display: inline-block;
    }

    @media (min-width: 1000px) {
        .info-area {
            padding: 0.1rem 0.6rem 0 0.6rem;
        }

        .name-area {
            margin-top: 0.1rem;
        }

        .select-input select {
            height: 0.7rem;
        }

        .select-input {
            margin-top: 0.1rem;
        }

        .login-button {
            margin-top: 0.2rem;
        }

        .login-button button {
            height: 0.7rem;
        }

        .qrcode-area{
            padding-top: 0.1rem;
        }

        .qrcode-area .content {
            width: 3rem;
            height: 3rem;
        }
    }
</style>
